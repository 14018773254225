<template>
  <div class="flex flex-center ag-center" style="height:100%;">
    <div class="co-66 size-36 fw-5">敬请期待！</div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>